import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import BigNumber from "bignumber.js";
import { Helmet } from "react-helmet-async";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Tabs, Row, Col, Space, Divider, Tooltip } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import LinkFormat from "common/LinkFormat";
import { TxParamsType } from "constants/types";
import {
  addressCheckSum,
  formatNumber,
  timeFormatDistance,
  timeFormatUtc,
  toNeon,
} from "utils";
import {
  useGetTransactionDetailQuery,
  useGetSolTxsQuery,
} from "app/services/transaction";
import { TxActionType } from "constants/types";
import { useWindowSize } from "app/hooks";

import TabsWithHash from "common/TabWithHash";
import {
  Wrapper,
  Container,
  TabStyle,
  TextMain,
  TextOverlay,
  BorderStyle,
  TabBoxDetail,
  SpaceStyle,
  BoxInner,
  TextSkeleton,
} from "common/styledGeneral";
import { PageTitle } from "common/PageTitle";
import RowLoading from "common/RowLoading";
import { TransactionDetailLog } from "features/transaction/TransactionDetailLog";
import CopyIcon from "common/CopyIcon";
import TxParseData from "features/transaction/module/TxParseData";
import iconArrowRight from "assets/images/icon-arrow-right.svg";
import { SolTxs } from "features/transaction/module/SolTxs";
import { Status } from "features/transaction/module/Status";
import { ViewTxsSolana, LogoSolana } from "./transactionStyled";
import solanaLogo from "assets/images/s-logo.svg";
import { APPROVE_NUMBER } from "constants/themeConfig";
import { ADDRESS_MAPPER } from "constants/address_mapper";

const { TabPane } = Tabs;

export function TransactionDetail() {
  const [viewSolTxs, setViewSolTxs] = useState(false);
  const { hash } = useParams<TxParamsType>();
  const { width } = useWindowSize();
  const { data, error, isLoading } = useGetTransactionDetailQuery(
    hash ?? skipToken
  );

  const {
    data: solTxs,
    error: solTxError,
    isLoading: txLoading,
    refetch,
  } = useGetSolTxsQuery(hash ?? skipToken);

  const actions = useMemo(() => {
    if (data?.data?.actions) {
      const a = data.data.actions.reduce((action: any, curr: TxActionType) => {
        if (!action[curr.type]) {
          action[curr.type] = [curr];
        } else {
          action[curr.type] = [...action[curr.type], curr];
        }

        return action;
      }, {});
      return a;
    }
    return {};
  }, [data?.data?.actions]);
  const newData = data?.data && { ...data.data, actions };
  const feeTx = useMemo(() => {
    if (newData?.gasPrice) {
      const value = new BigNumber(newData.gasPrice)
        .multipliedBy(newData.gasUsed)
        .dividedBy(10 ** 18)
        .toNumber();
      const decimalPlaces = Math.abs(new BigNumber(value).decimalPlaces());
      return new BigNumber(value).toFixed(decimalPlaces);
    }
    return false;
  }, [newData?.gasPrice]);

  useEffect(() => {
    const timeCall = setInterval(() => {
      if (solTxs?.data && solTxs.data.length == 0) {
        refetch();
      }
    }, 3000);
    if (solTxs?.data && solTxs.data.length > 0) {
      clearInterval(timeCall);
    }
  }, [solTxs]);
  if ((data?.success && !data?.data) || (!data?.success && data?.data) || error)
    return <Navigate to={`/search?q=${hash}`} />;
  return (
    <Wrapper>
      {hash && (
        <Helmet>
          <title>{`Transaction - ${hash}`}</title>
        </Helmet>
      )}
      <Container className="mx-md-down-0">
        {isLoading ? (
          <RowLoading />
        ) : (
          <TabStyle>
            <PageTitle title="Transaction Details" />
            <TabBoxDetail>
              <TabsWithHash type="card" tabKeys={["balanceChange", "logs"]}>
                <TabPane tab="Details" key="default">
                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">Tx hash &nbsp;</TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <Space className="word-break mv-2" align="start">
                        <TextMain className="word-break">
                          {newData.transactionHash}
                        </TextMain>
                        <CopyIcon
                          value={newData.transactionHash}
                          after={true}
                        />
                        {width && width > 991 && (
                          <ViewTxsSolana
                            onClick={() => setViewSolTxs(!viewSolTxs)}
                          >
                            <Space>
                              <LogoSolana src={solanaLogo} alt="Solana logo" />
                              <TextOverlay>
                                {`${viewSolTxs ? "Hide" : "View"} ${
                                  solTxs?.data && solTxs.data.length > 0 ? (
                                    solTxs.data.length
                                  ) : (
                                    <TextSkeleton size="10px" />
                                  )
                                } Solana Txs`}
                                &nbsp;
                                {viewSolTxs ? (
                                  <ArrowUpOutlined />
                                ) : (
                                  <ArrowDownOutlined />
                                )}
                              </TextOverlay>
                            </Space>
                          </ViewTxsSolana>
                        )}
                      </Space>
                      {width && width < 992 && (
                        <ViewTxsSolana
                          onClick={() => setViewSolTxs(!viewSolTxs)}
                        >
                          <Space>
                            <LogoSolana src={solanaLogo} alt="Solana logo" />
                            <TextOverlay>
                              {`${viewSolTxs ? "Hide" : "View"} ${
                                solTxs?.data ? solTxs.data.length : 0
                              } Solana Txs`}
                              &nbsp;
                              {viewSolTxs ? (
                                <ArrowUpOutlined />
                              ) : (
                                <ArrowDownOutlined />
                              )}
                            </TextOverlay>
                          </Space>
                        </ViewTxsSolana>
                      )}
                      <SolTxs data={solTxs?.data || []} show={viewSolTxs} />
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">Status &nbsp;</TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <Status
                        solTx={
                          solTxs?.data && solTxs.data.length > 0
                            ? solTxs.data.slice(-1)
                            : ""
                        }
                        status={newData?.status}
                        statusMgs={
                          newData?.status === "0" ? newData?.error?.name : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">Block &nbsp;</TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <TextMain className="mv-2">
                        <Link to={`/block/${newData.blockNumber}`}>
                          #{newData.blockNumber}
                        </Link>
                      </TextMain>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Timestamp &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <Space wrap className="word-break mt-1">
                        <TextMain>
                          {timeFormatDistance(newData.timestamp)}
                        </TextMain>
                        <Divider type="vertical" />
                        <TextOverlay>
                          {timeFormatUtc(newData.timestamp)}
                        </TextOverlay>
                      </Space>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">From &nbsp;</TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <TextMain className="mv-2">
                        <Space align="start">
                          {/* <LinkFormat
                            title={
                              ADDRESS_MAPPER[newData.from.toLowerCase()] || ""
                            }
                            type="address"
                            value={newData.from}
                          /> */}
                          <Link
                            className="word-break"
                            to={`/address/${newData.from.toLowerCase()}`}
                          >
                            {ADDRESS_MAPPER[newData.from.toLowerCase()] ||
                              addressCheckSum(newData.from)}
                          </Link>
                          {/* Binance Hot Wallet 1
                          <Tooltip
                            placement="top"
                            title="Tooltip: Lorem ipsum dolor sit amet"
                            color="#4A2F4A"
                          >
                            <InfoCircleFilled />
                          </Tooltip> */}
                          {/* <Divider type="vertical" /> */}
                          {/* <Link to={`/address/${newData.from}`}>
                            {ADDRESS_MAPPER[newData.from.toLowerCase()] ||
                              newData.from}
                          </Link> */}
                          <CopyIcon value={newData.from} after={true} />
                        </Space>
                      </TextMain>
                    </Col>
                  </Row>
                  {newData?.to && (
                    <Row gutter={{ xs: 10, md: 24 }}>
                      <Col span={8} md={5} lg={4}>
                        <TextOverlay className="mv-2">To &nbsp;</TextOverlay>
                      </Col>
                      <Col span={16} md={19} lg={20}>
                        <TextMain className="mv-2">
                          <Space align="start">
                            {/* <LinkFormat
                              title={
                                ADDRESS_MAPPER[newData.to.toLowerCase()] || ""
                              }
                              type="address"
                              value={newData.to}
                            /> */}
                            <Link
                              className="word-break"
                              to={`/address/${newData.to.toLowerCase()}`}
                            >
                              {ADDRESS_MAPPER[newData.to.toLowerCase()] ||
                                addressCheckSum(newData.to)}
                            </Link>
                            {/* Binance Hot Wallet 1
                          <Tooltip
                            placement="top"
                            title="Tooltip: Lorem ipsum dolor sit amet"
                            color="#4A2F4A"
                          >
                            <InfoCircleFilled />
                          </Tooltip> */}
                            {/* <Divider type="vertical" /> */}
                            {/* <Link to={`/address/${newData.to}`}>
                              {ADDRESS_MAPPER[newData.to.toLowerCase()] ||
                                newData.to}
                            </Link> */}
                            <CopyIcon value={newData.to} after={true} />
                          </Space>
                        </TextMain>
                      </Col>
                    </Row>
                  )}
                  {!newData?.to && newData?.contractAddress && (
                    <Row gutter={{ xs: 10, md: 24 }}>
                      <Col span={8} md={5} lg={4}>
                        <TextOverlay className="mv-2">To &nbsp;</TextOverlay>
                      </Col>
                      <Col span={16} md={19} lg={20}>
                        <TextMain className="mv-2">
                          <Space wrap className="word-break" align="start">
                            {/* Binance Hot Wallet 1
                            <Tooltip
                              placement="top"
                              title="Tooltip: Lorem ipsum dolor sit amet"
                              color="#4A2F4A"
                            >
                              <InfoCircleFilled />
                            </Tooltip> */}
                            {/* <Divider type="vertical" /> */}
                            <TextMain>[Contract </TextMain>
                            <Link to={`/address/${newData.contractAddress}`}>
                              {newData.contractAddress}
                            </Link>
                            <TextMain>Created] </TextMain>
                            <CopyIcon
                              value={newData.contractAddress}
                              after={true}
                            />
                          </Space>
                        </TextMain>
                      </Col>
                    </Row>
                  )}
                  {newData?.actions[newData.type] &&
                    newData.actions[newData.type].length > 0 && (
                      <Divider className="my-1" />
                    )}
                  {(newData?.actions?.approve ||
                    newData?.actions?.swap ||
                    newData?.actions?.mint_add_liquidity ||
                    newData?.actions?.burn_remove_liquidity) && (
                    <Row gutter={{ xs: 10, md: 24 }}>
                      <Col span={24}>
                        <TextOverlay className="mv-2">
                          Main actions &nbsp;
                        </TextOverlay>
                      </Col>
                      <Col span={24}>
                        <BorderStyle>
                          <BoxInner>
                            {newData?.actions?.approve &&
                              newData.actions.approve.length > 0 &&
                              newData.actions.approve.map(
                                (item: any, i: number) => {
                                  return (
                                    <SpaceStyle
                                      key={i}
                                      className="all-box-ellipsis word-break mb-1"
                                    >
                                      <TextMain className="word-break">
                                        {item.name}&nbsp;
                                        {item.data.amount === APPROVE_NUMBER
                                          ? " Unlimited"
                                          : formatNumber(
                                              new BigNumber(item.data.amount)
                                                .dividedBy(
                                                  10 ** item.data.tokenDecimals
                                                )
                                                .toString()
                                            )}
                                        &nbsp;
                                        <Link
                                          className="mr-1 nowrap"
                                          to={`/token/${item.data.address}`}
                                        >
                                          {item.data.tokenSymbol}
                                        </Link>
                                        <TextOverlay className="mr-05 nowrap">
                                          On
                                        </TextOverlay>
                                        <LinkFormat
                                          type="address"
                                          value={item.data.owner}
                                          truncate={true}
                                        />
                                        <CopyIcon
                                          value={item.data.owner}
                                          after={true}
                                        />
                                      </TextMain>
                                    </SpaceStyle>
                                  );
                                }
                              )}
                            {newData?.actions?.swap &&
                              newData.actions.swap.length > 0 &&
                              newData.actions.swap.map(
                                (item: any, i: number) => {
                                  return (
                                    <SpaceStyle
                                      key={i}
                                      className="all-box-ellipsis"
                                    >
                                      <TextMain className="word-break">
                                        {item.name}&nbsp;&nbsp;
                                        {formatNumber(
                                          new BigNumber(
                                            item.data.inPoolToken.amount
                                          )
                                            .dividedBy(
                                              10 **
                                                item.data.inPoolToken
                                                  .tokenDecimals
                                            )
                                            .toString()
                                        )}
                                        &nbsp;
                                        <Link
                                          className="mr-1 nowrap"
                                          to={`/token/${item.data.inPoolToken.address}`}
                                        >
                                          {item.data.inPoolToken.tokenSymbol}
                                        </Link>
                                        <TextOverlay className="mr-05 nowrap">
                                          For&nbsp;
                                        </TextOverlay>
                                        {formatNumber(
                                          new BigNumber(
                                            item.data.outPoolToken.amount
                                          )
                                            .dividedBy(
                                              10 **
                                                item.data.outPoolToken
                                                  .tokenDecimals
                                            )
                                            .toString()
                                        )}
                                        &nbsp;
                                        <Link
                                          className="mr-1 nowrap"
                                          to={`/token/${item.data.outPoolToken.address}`}
                                        >
                                          {item.data.outPoolToken.tokenSymbol}
                                        </Link>
                                        <TextOverlay className="mr-05 nowrap">
                                          On
                                        </TextOverlay>
                                        <LinkFormat
                                          type="address"
                                          value={item.data.lp}
                                          truncate={true}
                                        />
                                        <CopyIcon
                                          value={item.data.lp}
                                          after={true}
                                        />
                                      </TextMain>
                                    </SpaceStyle>
                                  );
                                }
                              )}
                            {newData?.actions?.mint_add_liquidity &&
                              newData.actions.mint_add_liquidity.length > 0 &&
                              newData.actions.mint_add_liquidity.map(
                                (item: any, i: number) => {
                                  return (
                                    <SpaceStyle
                                      key={i}
                                      className="all-box-ellipsis word-break"
                                    >
                                      <TextMain className="word-break">
                                        Supply&nbsp;&nbsp;
                                        {formatNumber(
                                          new BigNumber(item.data.token0Amount)
                                            .dividedBy(
                                              10 ** item.data.token0Decimals
                                            )
                                            .toString()
                                        )}
                                        &nbsp;
                                        <Link
                                          className="mr-1"
                                          to={`/token/${item.data.token0Address}`}
                                        >
                                          {item.data.token0Symbol}
                                        </Link>
                                        <TextOverlay className="mr-05 nowrap">
                                          And
                                        </TextOverlay>
                                        {formatNumber(
                                          new BigNumber(item.data.token1Amount)
                                            .dividedBy(
                                              10 ** item.data.token1Decimals
                                            )
                                            .toString()
                                        )}
                                        &nbsp;
                                        <Link
                                          className="mr-1 word-break"
                                          to={`/token/${item.data.token1Address}`}
                                        >
                                          {item.data.token1Symbol}
                                        </Link>
                                        &nbsp;
                                        <TextOverlay className="mr-05 nowrap">
                                          To
                                        </TextOverlay>
                                        <LinkFormat
                                          type="token"
                                          value={item.data.address}
                                          truncate={true}
                                        />
                                        <CopyIcon
                                          value={item.data.address}
                                          after={true}
                                        />
                                      </TextMain>
                                    </SpaceStyle>
                                  );
                                }
                              )}
                            {newData?.actions?.burn_remove_liquidity &&
                              newData.actions.burn_remove_liquidity.length >
                                0 &&
                              newData.actions.burn_remove_liquidity.map(
                                (item: any, i: number) => {
                                  return (
                                    <SpaceStyle
                                      key={i}
                                      className="all-box-ellipsis word-break"
                                    >
                                      <TextMain className="word-break">
                                        Remove&nbsp;
                                        {formatNumber(
                                          new BigNumber(item.data.token0Amount)
                                            .dividedBy(
                                              10 ** item.data.token0Decimals
                                            )
                                            .toString()
                                        )}
                                        &nbsp;
                                        <Link
                                          className="word-break mr-1"
                                          to={`/token/${item.data.token0Address}`}
                                        >
                                          {item.data.token0Symbol}
                                        </Link>
                                        <TextOverlay className="mr-1 nowrap">
                                          and&nbsp;
                                        </TextOverlay>
                                        {formatNumber(
                                          new BigNumber(item.data.token1Amount)
                                            .dividedBy(
                                              10 ** item.data.token1Decimals
                                            )
                                            .toString()
                                        )}
                                        &nbsp;
                                        <Link
                                          className="word-break mr-1"
                                          to={`/token/${item.data.token1Address}`}
                                        >
                                          {item.data.token1Symbol}
                                        </Link>
                                        <TextOverlay className="mr-1 nowrap">
                                          From&nbsp;
                                        </TextOverlay>
                                        <LinkFormat
                                          type="token"
                                          value={item.data.address}
                                          truncate={true}
                                        />
                                        <CopyIcon
                                          value={item.data.address}
                                          after={true}
                                        />
                                      </TextMain>
                                    </SpaceStyle>
                                  );
                                }
                              )}
                          </BoxInner>
                        </BorderStyle>
                      </Col>
                    </Row>
                  )}
                  {newData?.actions?.transfer &&
                    newData.actions.transfer.length > 0 && (
                      <Row gutter={{ xs: 10, md: 24 }}>
                        <Col span={24}>
                          <TextOverlay className="mv-2">
                            Tokens Transferred&nbsp;
                          </TextOverlay>
                        </Col>
                        <Col span={24}>
                          <BorderStyle>
                            <BoxInner>
                              {newData.actions.transfer.map(
                                (item: any, i: number) => {
                                  return (
                                    <SpaceStyle
                                      key={i}
                                      className="all-box-ellipsis word-break mb-1"
                                    >
                                      <TextMain className="word-break">
                                        <TextOverlay className="nowrap">
                                          From&nbsp;
                                        </TextOverlay>
                                        <LinkFormat
                                          title={
                                            ADDRESS_MAPPER[
                                              item.data.from.toLowerCase()
                                            ] || ""
                                          }
                                          type="address"
                                          value={item.data.from}
                                          truncate={true}
                                        />
                                        <TextOverlay className="mr-1">
                                          <CopyIcon
                                            value={item.data.from}
                                            after={true}
                                          />
                                        </TextOverlay>
                                        <img
                                          className="mr-1"
                                          src={iconArrowRight}
                                        />
                                        <TextOverlay className="nowrap">
                                          To&nbsp;
                                        </TextOverlay>
                                        <LinkFormat
                                          title={
                                            ADDRESS_MAPPER[
                                              item.data.to.toLowerCase()
                                            ] || ""
                                          }
                                          type="address"
                                          value={item.data.to}
                                          truncate={true}
                                        />
                                        <TextOverlay className="mr-1">
                                          <CopyIcon
                                            value={item.data.to}
                                            after={true}
                                          />
                                        </TextOverlay>
                                        <TextOverlay className="mr-1">
                                          For
                                        </TextOverlay>
                                        {item?.data?.amount &&
                                        item?.data?.tokenInfo?.decimals &&
                                        !item?.data?.tokenId ? (
                                          <span>
                                            {formatNumber(
                                              new BigNumber(item.data.amount)
                                                .dividedBy(
                                                  10 **
                                                    item.data.tokenInfo.decimals
                                                )
                                                .toString()
                                            )}
                                            &nbsp;&nbsp;
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {item?.data?.tokenId && (
                                          <span>{`[Token ID: ${item.data.tokenId}]`}</span>
                                        )}
                                        &nbsp;
                                        {/* {item?.data?.amount &&
                                          item?.data?.tokenInfo?.decimals &&
                                          formatNumber(
                                            new BigNumber(item.data.amount)
                                              .dividedBy(
                                                10 **
                                                  item.data.tokenInfo.decimals
                                              )
                                              .toString()
                                          )}
                                        &nbsp; */}
                                        <Link
                                          className="nowrap"
                                          to={`/token/${item.data.address.toLowerCase()}`}
                                        >
                                          {item?.data?.tokenInfo?.symbol || ""}
                                        </Link>
                                      </TextMain>
                                    </SpaceStyle>
                                  );
                                }
                              )}
                            </BoxInner>
                          </BorderStyle>
                        </Col>
                      </Row>
                    )}
                  <Divider className="my-1" />

                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">Value &nbsp;</TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <TextMain className="mv-2">
                        {formatNumber(toNeon(newData.value))}{" "}
                        <TextOverlay>
                          {newData?.gasToken?.tokenName || "NEON"}
                        </TextOverlay>
                      </TextMain>
                    </Col>
                  </Row>

                  <Divider className="my-1" />

                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">Fee &nbsp;</TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      {newData?.gasPrice && (
                        // <Tooltip
                        //   title={new BigNumber(newData.gasPrice)
                        //     .multipliedBy(newData.gasUsed)
                        //     .dividedBy(10 ** 18)
                        //     .toString()}
                        // >
                        <TextMain className="mv-2">
                          {feeTx}{" "}
                          <TextOverlay>
                            {newData?.gasToken?.tokenName === "SOL"
                              ? "SOL"
                              : newData?.gasToken?.tokenName === "USDC"
                              ? "USDC"
                              : "NEON"}
                          </TextOverlay>
                        </TextMain>
                        // </Tooltip>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Gas price &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <TextMain className="mv-2">
                        {formatNumber(
                          new BigNumber(newData.gasPrice)
                            .dividedBy(10 ** 9)
                            .toString()
                        )}{" "}
                        {newData?.gasToken?.tokenName ? (
                          <>
                            <TextOverlay>
                              {newData.gasToken?.tokenName === "SOL"
                                ? "Lamports"
                                : newData.gasToken?.tokenName === "USDC"
                                ? "Nanocents"
                                : "Galan"}
                            </TextOverlay>
                            &nbsp;&nbsp;
                            <Tooltip
                              title={
                                newData.gasToken?.tokenName === "SOL"
                                  ? "1 SOL = 10^9 Lamports"
                                  : newData.gasToken?.tokenName === "USDC"
                                  ? "1 USDC = 10^9 Nanocents"
                                  : "1 NEON = 10^9 Galan"
                              }
                            >
                              <TextOverlay>
                                <InfoCircleOutlined />
                              </TextOverlay>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <TextOverlay>Galan</TextOverlay>&nbsp;&nbsp;
                            <Tooltip title="1 NEON = 10^9 Galan">
                              <TextOverlay>
                                <InfoCircleOutlined />
                              </TextOverlay>
                            </Tooltip>
                          </>
                        )}
                      </TextMain>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Gas limit &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <TextMain className="mv-2">
                        <Space>{formatNumber(newData.gas)}</Space>
                      </TextMain>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">
                        Gas used &nbsp;
                      </TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <TextMain className="mv-2">
                        {formatNumber(newData.gasUsed)}
                      </TextMain>
                      <TextOverlay style={{ marginLeft: 5 }}>
                        (Used{" "}
                        {formatNumber(
                          new BigNumber(newData.gasUsed)
                            .multipliedBy(100)
                            .dividedBy(newData.gas)
                            .toString(),
                          2
                        )}
                        %)
                      </TextOverlay>
                    </Col>
                  </Row>

                  <Divider className="my-1" />

                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={8} md={5} lg={4}>
                      <TextOverlay className="mv-2">Nonce &nbsp;</TextOverlay>
                    </Col>
                    <Col span={16} md={19} lg={20}>
                      <TextMain className="mv-2">{newData.nonce}</TextMain>
                    </Col>
                  </Row>

                  <Divider className="my-1" />

                  <Row gutter={{ xs: 10, md: 24 }}>
                    <Col span={24}>
                      <TextOverlay className="mv-2">Data &nbsp;</TextOverlay>
                    </Col>
                    <Col span={24}>
                      <TxParseData
                        data={newData?.parsedInput ? newData.parsedInput : []}
                        input={newData?.input ? newData.input : ""}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {newData?.logs && newData.logs.length > 0 && (
                  <TabPane tab="Logs" key="logs">
                    <TransactionDetailLog data={newData.logs} />
                  </TabPane>
                )}
              </TabsWithHash>
            </TabBoxDetail>
          </TabStyle>
        )}
      </Container>
    </Wrapper>
  );
}
