import solLogo from "assets/images/logo/sol.svg";
import neonLogo from "assets/images/logo/neon.png";
import usdcLogo from "assets/images/logo/usdc.png";

const FEE_TOKEN: any = {
  neon: neonLogo,
  sol: solLogo,
  usdc: usdcLogo,
};

export { FEE_TOKEN };
