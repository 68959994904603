import React from "react";
import BigNumber from "bignumber.js";
import { Link } from "react-router-dom";
import { Image, Space, Tooltip } from "antd";
import {
  FileTextOutlined,
  CopyrightOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import { addressCheckSum, formatNumber, hexToDec } from "utils";
import LinkFormat from "common/LinkFormat";
import { TextMain } from "common/styledGeneral";
import { ADDRESS_MAPPER } from "constants/address_mapper";
import iconNeon from "assets/images/neonlabs.png";
import iconSolana from "assets/images/s-logo.svg";
import { FEE_TOKEN } from "constants/general";

export const txColumnsToken = [
  {
    title: "TX HASH",
    dataIndex: "hash",
    width: "230px",
    render: (hash: string, record: any) => (
      <LinkFormat type="tx" value={hash} status={record.status} copy={true} />
    ),
  },
  {
    title: "METHOD",
    dataIndex: "method",
    width: "130px",
    ellipsis: true,
  },
  {
    title: "BLOCK",
    dataIndex: "blockNumber",
    ellipsis: true,
    width: "140px",
    render: (blockNumber: number) => (
      <Link to={`/block/${blockNumber}`}>#{blockNumber}</Link>
    ),
  },
  {
    title: "TIME",
    dataIndex: "timestamp",
    ellipsis: true,
    width: "170px",
  },
  {
    title: "FROM",
    dataIndex: "from",
    width: "190px",
    render: (from: string, record: any) => {
      if (from) {
        if (
          record?.currentAddress &&
          record.currentAddress.toLowerCase().includes(from.toLowerCase())
        ) {
          return (
            <Tooltip color="#4A2F4A" title={from}>
              <TextMain className="text-ellipsis">
                {ADDRESS_MAPPER[from.toLowerCase()] || addressCheckSum(from)}
              </TextMain>
            </Tooltip>
          );
        } else {
          return (
            <LinkFormat
              title={ADDRESS_MAPPER[from.toLowerCase()] || ""}
              type="address"
              value={addressCheckSum(from)}
              copy={true}
            />
          );
        }
      }
    },
  },
  {
    title: "TO",
    dataIndex: "to",
    ellipsis: true,
    width: "180px",
    render: (to: string, record: any) => {
      if (
        to &&
        record?.currentAddress &&
        record.currentAddress.toLowerCase().includes(to.toLowerCase())
      ) {
        return (
          <Tooltip color="#4A2F4A" title={to}>
            <TextMain className="text-ellipsis">
              {ADDRESS_MAPPER[to.toLowerCase()] || addressCheckSum(to)}
            </TextMain>
          </Tooltip>
        );
      } else {
        return (
          <>
            {to ? (
              <LinkFormat
                title={ADDRESS_MAPPER[to.toLowerCase()] || ""}
                type="address"
                value={addressCheckSum(to)}
                truncate={false}
                copy={true}
              />
            ) : record?.contractAddress ? (
              <Tooltip title={record.contractAddress} color="#4A2F4A">
                <Link to={`/address/${record.contractAddress}`}>
                  <TextMain style={{ marginRight: 5, display: "inline-block" }}>
                    <FileDoneOutlined />
                  </TextMain>
                  Contract Creation
                </Link>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        );
      }
    },
  },
  {
    title: "VALUE (NEON)",
    dataIndex: "value",
    width: "140px",
    render: (value: string) => (
      <TextMain>
        {formatNumber(new BigNumber(value).dividedBy(10 ** 18).toString())}
      </TextMain>
    ),
  },
  {
    title: "FEE VALUE",
    dataIndex: "fee",
    ellipsis: true,
    width: "170px",
    render: (fee: string, record: any) => (
      <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
        <img
          src={FEE_TOKEN[record?.gasToken?.tokenName.toLowerCase() || "neon"]}
          width={18}
        />
        <TextMain>
          {formatNumber(new BigNumber(fee).dividedBy(10 ** 18).toString(), 9)}
        </TextMain>
      </div>
    ),
  },
];

export const neonColumnsTransfer = [
  {
    title: "TX HASH",
    dataIndex: "transactionHash",
    width: "250px",
    render: (hash: string, record: any) => (
      <LinkFormat type="tx" value={hash} status={record.status} copy={true} />
    ),
    // <div className="d-flex">
    //   <img style={{ marginRight: "5px" }} width="24" src={iconFailed} />
    //   <LinkFormat type="tx" value={hash} />
    // </div>
  },
  {
    title: "TIME",
    dataIndex: "timestamp",
    ellipsis: true,
    width: "170px",
  },
  {
    title: "FROM",
    dataIndex: "from",
    width: "150px",
    render: (from: string, record: any) => {
      if (from) {
        if (
          record?.currentAddress &&
          record.currentAddress.toLowerCase().includes(from.toLowerCase())
        ) {
          return (
            <Tooltip color="#4A2F4A" title={addressCheckSum(from)}>
              <TextMain className="text-ellipsis">
                {ADDRESS_MAPPER[from.toLowerCase()] || addressCheckSum(from)}
              </TextMain>
            </Tooltip>
          );
        } else {
          return (
            <LinkFormat
              title={ADDRESS_MAPPER[from.toLowerCase()] || ""}
              type="address"
              copy={true}
              value={addressCheckSum(from)}
            />
          );
        }
      }
    },
  },
  {
    title: "TO",
    dataIndex: "to",
    width: "150px",
    render: (to: string, record: any) => {
      if (to) {
        if (
          record?.currentAddress &&
          record.currentAddress.toLowerCase().includes(to.toLowerCase())
        ) {
          return (
            <Tooltip color="#4A2F4A" title={addressCheckSum(to)}>
              <TextMain className="text-ellipsis">
                {ADDRESS_MAPPER[to.toLowerCase()] || addressCheckSum(to)}
              </TextMain>
            </Tooltip>
          );
        } else {
          return (
            <LinkFormat
              title={ADDRESS_MAPPER[to.toLowerCase()] || ""}
              type="address"
              copy={true}
              value={addressCheckSum(to)}
            />
          );
        }
      }
    },
  },
  {
    title: "VALUE",
    dataIndex: "amount",
    width: "180px",
    render: (amount: number, record: any) => (
      <TextMain>
        {formatNumber(
          new BigNumber(amount).dividedBy(10 ** record.decimals).toString()
        )}
      </TextMain>
    ),
  },
  {
    title: "TOKEN",
    dataIndex: "tokenName",
    render: (tokenName: string, record: any) => (
      <Space>
        <img width="24" src={iconNeon} />
        {`${tokenName} (${record.symbol})`}
      </Space>
    ),
    width: "230px",
  },
  {
    title: "FEE (NEON)",
    dataIndex: "fee",
    ellipsis: true,
    width: "110px",
    render: (fee: string) => (
      <TextMain>
        {formatNumber(new BigNumber(fee).dividedBy(10 ** 18).toString(), 9)}
      </TextMain>
    ),
  },
];

export const tfColumnsToken = [
  {
    title: "TX HASH",
    dataIndex: "transactionHash",
    width: "250px",
    render: (hash: string, record: any) => (
      <LinkFormat type="tx" value={hash} status={record.status} copy={true} />
    ),
  },
  {
    title: "TIME",
    dataIndex: "timestamp",
    ellipsis: true,
    width: "200px",
  },
  {
    title: "FROM",
    dataIndex: "from",
    width: "170px",
    render: (from: string, record: any) => {
      if (from) {
        if (
          record?.currentAddress &&
          record.currentAddress.toLowerCase().includes(from.toLowerCase())
        ) {
          return (
            <Tooltip color="#4A2F4A" title={addressCheckSum(from)}>
              <TextMain className="text-ellipsis">
                {ADDRESS_MAPPER[from.toLowerCase()] || addressCheckSum(from)}
              </TextMain>
            </Tooltip>
          );
        } else {
          return (
            <LinkFormat
              title={ADDRESS_MAPPER[from.toLowerCase()] || ""}
              type="address"
              copy={true}
              value={addressCheckSum(from)}
            />
          );
        }
      }
    },
  },
  {
    title: "TO",
    dataIndex: "to",
    width: "170px",
    render: (to: string, record: any) => {
      if (to) {
        if (
          record?.currentAddress &&
          record.currentAddress.toLowerCase().includes(to.toLowerCase())
        ) {
          return (
            <Tooltip color="#4A2F4A" title={addressCheckSum(to)}>
              <TextMain className="text-ellipsis">
                {ADDRESS_MAPPER[to.toLowerCase()] || addressCheckSum(to)}
              </TextMain>
            </Tooltip>
          );
        } else {
          return (
            <LinkFormat
              title={ADDRESS_MAPPER[to.toLowerCase()] || ""}
              type="address"
              copy={true}
              value={addressCheckSum(to)}
            />
          );
        }
      }
    },
  },
  {
    title: "VALUE",
    dataIndex: "amount",
    width: "150px",
    render: (amount: number, record: any) => (
      <TextMain>
        {formatNumber(
          new BigNumber(amount).dividedBy(10 ** record.decimals).toString()
        )}
      </TextMain>
    ),
  },
  {
    title: "TOKEN",
    dataIndex: "tokenName",
    render: (tokenName: string, record: any) => (
      <Link to={`/token/${record.tokenAddress.toLowerCase()}`}>
        <Space>
          {record.logoURI ? (
            <img width="24" src={record.logoURI} />
          ) : (
            <CopyrightOutlined style={{ fontSize: "24px" }} />
          )}
          {`${tokenName} (${record.symbol})`}
        </Space>
      </Link>
    ),
    width: "230px",
  },
  {
    title: "FEE (NEON)",
    dataIndex: "fee",
    ellipsis: true,
    width: "110px",
    render: (fee: string) =>
      fee && (
        <TextMain>
          {formatNumber(new BigNumber(fee).dividedBy(10 ** 18).toString(), 9)}
        </TextMain>
      ),
  },
];

export const tokenBalanceColumn = [
  {
    title: "NAME",
    dataIndex: "tokenName",
    width: "310px",
    render: (tokenName: string, record: any) => (
      <Link to={`/token/${record.tokenAddress}`}>
        <Space>
          {record.logoURI ? (
            <img width="24" src={record.logoURI} />
          ) : (
            <CopyrightOutlined style={{ fontSize: "24px" }} />
          )}
          {tokenName}
        </Space>
      </Link>
    ),
  },
  {
    title: "",
    dataIndex: "symbol",
    render: (symbol: string) => <TextMain>{symbol}</TextMain>,
    width: "100px",
  },
  {
    title: "AMOUNT",
    dataIndex: "amountUi",
    render: (amountUi: number) => (
      <TextMain>{amountUi ? formatNumber(amountUi) : ""}</TextMain>
    ),
    width: "150px",
  },

  {
    title: "PRICE",
    dataIndex: "price",
    render: (price: number) => (
      <TextMain>{price ? "$" + formatNumber(price) : ""}</TextMain>
    ),
    width: "150px",
  },
  {
    title: "VALUE",
    dataIndex: "value",
    render: (value: number) => (
      <TextMain>{value ? "$" + formatNumber(value) : ""}</TextMain>
    ),
    width: "150px",
  },
];

export const verifiedContractColumn = [
  {
    title: "#",
    dataIndex: "number",
    render: (number: number) => <TextMain>{number}</TextMain>,
    width: 80,
  },
  {
    title: "Address",
    dataIndex: "address",
    render: (address: string) => (
      <LinkFormat type="address" value={addressCheckSum(address)} copy={true} />
    ),
    width: 200,
  },
  {
    title: "Contract Name",
    dataIndex: "contractName",
    render: (contractName: string) => (
      <TextMain>{contractName ? contractName : ""}</TextMain>
    ),
    width: 180,
  },

  {
    title: "Optimization",
    dataIndex: "optimization",
    render: (optimization: boolean) => <TextMain>{`${optimization}`}</TextMain>,
    width: 150,
  },
  {
    title: "Compiler Type",
    dataIndex: "compilerType",
    render: (compilerType: string) => <TextMain>{compilerType}</TextMain>,
    width: 150,
  },
  {
    title: "Compiler Version",
    dataIndex: "compiler",
    render: (compiler: string) => <TextMain>{compiler}</TextMain>,
    width: 200,
  },
  {
    title: "Txs Number",
    dataIndex: "txCount",
    render: (txCount: any) =>
      txCount && (
        <Space>
          {txCount.neonTxs && (
            <TextMain>
              <img width={18} src={iconNeon} alt="neonscan" />
              {formatNumber(txCount.neonTxs)}
              &nbsp;&nbsp;
            </TextMain>
          )}
          {txCount.solanaTxs && (
            <TextMain>
              <img width={14} src={iconSolana} alt="solana" />{" "}
              {formatNumber(txCount.solanaTxs)}
            </TextMain>
          )}
        </Space>
      ),
    width: 150,
  },
  {
    title: "Verified Time",
    dataIndex: "verifyTime",
    ellipsis: true,
    width: 200,
  },
];
