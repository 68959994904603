import React from "react";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { Image, Space, Tooltip } from "antd";
import { FileTextOutlined, FileDoneOutlined } from "@ant-design/icons";

import { addressCheckSum, formatNumber, hexToDec } from "utils";
import LinkFormat from "common/LinkFormat";
import { TextMain } from "common/styledGeneral";
import { ADDRESS_MAPPER } from "constants/address_mapper";
import { ethers } from "ethers";
import { FEE_TOKEN } from "constants/general";

export const txColumns = [
  {
    title: "TX HASH",
    dataIndex: "hash",
    width: "180px",
    ellipsis: true,
    render: (hash: string, record: any) => (
      <LinkFormat
        type="tx"
        copy={true}
        value={hash}
        truncate={false}
        status={record.status}
      />
    ),
  },
  {
    title: "METHOD",
    dataIndex: "method",
    width: "150px",
    ellipsis: true,
  },
  {
    title: "BLOCK",
    dataIndex: "blockNumber",
    ellipsis: true,
    width: "130px",
    render: (blockNumber: number) => (
      <Link to={`/block/${blockNumber}`}>#{blockNumber}</Link>
    ),
  },
  {
    title: "TIME",
    dataIndex: "timestamp",
    ellipsis: true,
    width: "180px",
  },
  {
    title: "FROM",
    dataIndex: "from",
    ellipsis: true,
    width: "180px",
    render: (from: string) => (
      <LinkFormat
        title={ADDRESS_MAPPER[from.toLowerCase()] || ""}
        type="address"
        value={addressCheckSum(from)}
        truncate={false}
        copy={true}
      />
    ),
  },
  {
    title: "TO",
    dataIndex: "to",
    ellipsis: true,
    width: "180px",
    render: (to: string, record: any) =>
      to ? (
        <LinkFormat
          title={ADDRESS_MAPPER[to.toLowerCase()] || ""}
          type="address"
          value={addressCheckSum(to)}
          truncate={false}
          copy={true}
        />
      ) : record?.contractAddress ? (
        <Tooltip title={record.contractAddress} color="#4A2F4A">
          <Link to={`/address/${record.contractAddress}`}>
            <TextMain style={{ marginRight: 5, display: "inline-block" }}>
              <FileDoneOutlined />
            </TextMain>
            Contract Creation
          </Link>
        </Tooltip>
      ) : (
        ""
      ),
  },
  {
    title: "VALUE",
    dataIndex: "value",
    render: (value: string) => (
      <TextMain>
        {formatNumber(new BigNumber(value).dividedBy(10 ** 18).toString(), 9)}
      </TextMain>
    ),
    width: "130px",
  },
  {
    title: "FEE VALUE",
    dataIndex: "fee",
    ellipsis: true,
    width: "150px",
    render: (fee: string, record: any) => (
      <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
        <img
          src={FEE_TOKEN[record?.gasToken?.tokenName.toLowerCase() || "neon"]}
          width={20}
        />
        <TextMain>
          {formatNumber(new BigNumber(fee).dividedBy(10 ** 18).toString(), 9)}
        </TextMain>
      </div>
    ),
  },
];

export const txParseDataColumns = [
  {
    title: "#",
    key: "index",
    dataIndex: "index",
  },
  {
    title: "NAME",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "TYPE",
    key: "type",
    dataIndex: "type",
  },
  {
    title: "DATA",
    key: "data",
    dataIndex: "data",
    render: (data: Array<string>, record: any) =>
      Array.isArray(data) && data.length > 0 ? (
        data.map((value: string, key: number) => {
          return (
            <div key={value + key}>
              {ethers.utils.isAddress(value) ? (
                <LinkFormat
                  type="address"
                  value={addressCheckSum(value)}
                  truncate={false}
                />
              ) : (
                <TextMain>{`${value}`}</TextMain>
              )}
            </div>
          );
        })
      ) : record.type === "address" ? (
        //@ts-ignore
        <Link to={`/address/${data ? data.toLowerCase() : ""}`}>{data}</Link>
      ) : (
        <TextMain>{data ? data.toLocaleString() : ""}</TextMain>
      ),
  },
];
